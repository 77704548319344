.scrollBar::-webkit-scrollbar {
  width: 8px;
  background: none;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.267);
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
}
